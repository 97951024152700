import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, CardActions, Button, withStyles } from '@material-ui/core';
import Grade from '@material-ui/icons/Grade';
import Schedule from '@material-ui/icons/Schedule';
import Commute from '@material-ui/icons/Commute';
import Person from '@material-ui/icons/Person';
const styles = {
    card: {
        minWidth: 275,
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        flex: '1 0 auto'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
    },
    pos: {
        marginBottom: 12,
    },
    backPrimary: {
        backgroundColor: '#d500f9',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSecondary: {
        backgroundColor: '#e91e63',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSec: {
        backgroundColor: '#009688',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    }
};


class TopLabel extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Link to="/student">
                            <Card raised className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Student Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            
                                    </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backPrimary}>
                                        <Grade fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Link to="/master/routine">
                            <Card raised className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                            Class Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            
                                    </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSecondary}>
                                        <Schedule fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Link to="/employee">
                            <Card raised className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                           Employee Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            
                                    </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSec}>
                                        <Person fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Link to="/leave/status">
                            <Card raised className={classes.card}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography className={classes.title} color="textSecondary" variant='h2' gutterBottom>
                                           Leave Management
                                    </Typography>
                                        <Typography variant="h6" component="h2">
                                            
                                    </Typography>
                                    </CardContent>
                                    <CardContent className={classes.backSec}>
                                        <Commute fontSize="large" />
                                    </CardContent>
                                </div>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


export default withStyles(styles)(TopLabel);