import React, { useState } from 'react'
import { Typography, Grid, Button, Card, CardContent, LinearProgress, Paper } from '@material-ui/core'


import { PostData, GetData, GetAddOnData } from '../../api/service'
import { Alert, AlertTitle } from '@material-ui/lab';
import Sel from 'react-select';



export default function SubjectPaperUpdate({ history, apikey, username, user }) {

    const [paper_data,setPaperData] = React.useState([]);
    const [isReady,setIsReady] = useState(false);
    const [s_roll_no,setSRollNo] = useState('');
    const [state, setState] = React.useState({
        roll_no:'',
        email:'',
        semester:'',
        course_type:'',
        name:'',
        paper_1: '',
        paper_2: '',
        paper_3: '',
        paper_4:'',
        paper_5:'',
        selectedOption1: null,
        selectedOption2: null,
        selectedOption3: null,
        selectedOption4: null,
        selectedOption5: null,
       
    })


    const [isLoaded, setIsLoaded] = React.useState(false)
    const [papers, setPapers] = React.useState([])
    const [semesters, setSemesters] = React.useState([])
    const [f_papers, setFPapers] = React.useState([])

    const [error, setError] = React.useState({
        semester:'',
        paper_1: '',
        paper_2: '',
        paper_3: '',
        paper_4:'',
        system_error: '',
    })

    function setInitialState(){
        setState({
        roll_no:'',
        email:'',
        semester:'',
        course_type:'',
        name:'',
        paper_1: '',
        paper_2: '',
        paper_3: '',
        paper_4:'',
        paper_5:'',
        selectedOption1: null,
        selectedOption2: null,
        selectedOption3: null,
        selectedOption4: null,
        selectedOption5: null,
        })
    }

    function onChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        if(e.target.name=='semester'){
            let paper = papers.filter((el)=>el.semester==e.target.value);
            setFPapers(paper);
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }
    }


    function handleValidation() {
        let isValid = true;
        let err = [];

        if (state.semester == '') {
            isValid = false;
            err['semester'] = 'Please fill up this field'
        } else {
            err['semester'] = '';
        }

        if (state.paper_1 == '') {
            isValid = false;
            err['paper_1'] = 'Please fill up this field'
        } else {
            err['paper_1'] = '';
        }

        if (state.paper_2 == '') {
            isValid = false;
            err['paper_2'] = 'Please fill up this field'
        } else {
            err['paper_2'] = '';
        }

        if (state.paper_3 == '') {
            isValid = false;
            err['paper_3'] = 'Please fill up this field'
        } else {
            err['paper_3'] = '';
        }

        if (state.paper_4 == '') {
            isValid = false;
            err['paper_4'] = 'Please fill up this field'
        } else {
            err['paper_4'] = '';
        }

       

        if (state.course_type == 'HONOURS' && (state.semester=='3' || state.semester=='5')) {
            if (state.paper_5 == '') {
                isValid = false;
                err['paper_5'] = 'Please fill up this field'
            } else {
                err['paper_5'] = '';
            } 
        }




        
        setError(err);
        
        return isValid;


    }

    function onSubmit() {
        if (handleValidation()) {
            PostData(`/updatemysubjectpaper`, {
                ...state,
                paper_data
            }).then((resp) => {
                alert(resp.status_text)
                setInitialState();
                setIsReady(false);
            }).catch((err) => {
                alert('error')
            })
        }
    }

    function getData() {
        GetData(`/getpapercode`).
        then((resp) => {

            if (resp.length>0) {
                setPapers(resp);
                setFPapers(resp);
                setIsLoaded(true)
            }
        })
    }

    function checkStudent (){
        if(s_roll_no !=''){
        PostData(`/${apikey}/${s_roll_no}/getstudentforpaper`)
        .then((resp)=>{
            if(resp){
            setState({...state,
               
                semester:resp.semester,
                email:resp.email,
                roll_no:resp.roll_no,
                course_type:resp.course_type,
                name:resp.name
            })
            setIsReady(true)
        }
        })
        }
    }

    React.useEffect(() => {
        getData();
       if(state.roll_no){
        let s =1;
        let sem = [];
        while(s<=state.semester){
            sem.push(s);
            s++;
        }
        setSemesters(sem);
    }
    }, [state.roll_no])


    function onPaperChange(type, value){
        
      
        if (type == 'paper_1') {
            if(value !=null){

                if(!paper_data.includes(value.paper_code)){
                    setPaperData([...paper_data,value.paper_code]);
            setState({
                ...state,
                selectedOption1: value,
                paper_1: value.paper_code,
                
            })
        }
            else setState({
                selectedOption1: null,
                paper_1: ''
            })

        }

            else setState({
                selectedOption1: null,
                paper_1: ''
            })
        }

        if (type == 'paper_2') {
            
            if(value !=null){

                if(!paper_data.includes(value.paper_code)){
                    setPaperData([...paper_data,value.paper_code]);
            setState({
                ...state,
                selectedOption2: value,
                paper_2: value.paper_code,
               
            })
        }
            else setState({
                selectedOption2: null,
                paper_2: ''
            })

        }

            else setState({
                selectedOption2: null,
                paper_2: ''
            })
        }

        if (type == 'paper_3') {
            
            if(value !=null){

                if(!paper_data.includes(value.paper_code)){
                    setPaperData([...paper_data,value.paper_code]);

                
            setState({
                ...state,
                selectedOption3: value,
                paper_3: value.paper_code,
                

            })
        }
            else setState({
                selectedOption3: null,
                paper_3: ''
            })

        }

            else setState({
                selectedOption3: null,
                paper_3 :''
            })
        }

        if (type == 'paper_4') {
            
            if(value !=null){

                if(!paper_data.includes(value.paper_code)){
                    setPaperData([...paper_data,value.paper_code]);

            setState({
                ...state,
                selectedOption4: value,
                paper_4: value.paper_code,
               

            })
        }
            else setState({
                selectedOption4: null,
                paper_4: ''
            })

        }

            else setState({
                selectedOption4: null,
                paper_4: ''
            })
        }

        if (type == 'paper_5') {
           
            if(value !=null){

                if(!paper_data.includes(value.paper_code)){
                    setPaperData([...paper_data,value.paper_code]);

            setState({
                ...state,
                selectedOption5: value,
                paper_5: value.paper_code,
               

            })
        }
            else setState({
                selectedOption5: null,
                paper_5: ''
            })

        }

            else setState({
                selectedOption5: null,
                paper_5: ''
            })
        }

    }




    return (
        <div>
            {!isReady && <Paper raised style={{minHeight:'100vh'}}>
            <CardContent>
                    <Typography variant="h6" >
                        Paper Update for Students
                    </Typography>
        <br />
                    <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Typography
                        variant="subtitle2"

                    >
                        Enter Student's Roll No (College Roll No) <span className="red-alert">*</span>
                    </Typography>
        <br />

        <input
                                className="form-control"
                                type="text"
                                name="s_roll_no"
                                required
                                value={s_roll_no}
                                onChange={(e)=>setSRollNo(e.target.value)}
                                placeholder="College Roll No"
                            />
                            </Grid>

<Grid item xs={12} sm={12} lg={12} md={12} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={() => checkStudent()}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
               
                </CardContent>
            </Paper>
                }

            {isReady && <Paper raised style={{minHeight:'100vh'}}>
                {!isLoaded && <LinearProgress />}
                {isLoaded && <CardContent>
                    <Typography variant="h6" >
                        Paper Update for Students
                    </Typography>
                    
                    <Alert severity="info">
                        <AlertTitle>Note</AlertTitle>
                        <ul>
                            <li>You have to update papers of all the semesters that you have appeared only.</li>
                            <li>Select the semester first.</li>
                            <li>Then select the papers of the respective semester and submit.Continue the process untill all the papers of all semesters are submited.</li>
                           
                            <li>Select only those subjects that are applicable to you.</li>
                            <li>Verify before submit. You can not modify later.</li>
                        </ul>
                    </Alert>

                    <br />


                    {error['system_error'] && error['system_error'] != '' &&  <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error['system_error']}
                        
                    </Alert>}

                    <Grid container spacing={2}>

                    <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                Student <span className="red-alert">*</span>
                            </Typography>


                            <input
                                className="form-control"
                                type="text"
                               readOnly
                                value={state.name}
                            />
                               
                        </Grid>

                    <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                Select Semester <span className="red-alert">*</span>
                            </Typography>


                            <select
                                className="form-control"
                                type="text"
                                name="semester"
                                required
                                value={state.semester}
                                onChange={onChange}
                            >
                                <option value="">Select</option>
                                {semesters.map((el, index) =>
                                    <option
                                       
                                        key={index} value={el}>{el}</option>
                                )}

                            </select>
                        </Grid>


                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                 Subject 1 <span className="red-alert">*</span>
                            </Typography>


                            <Sel
                            value={state.selectedOption1}
                            options={f_papers}
                            isLoading={!isLoaded}
                            onChange={onPaperChange.bind(this, 'paper_1')}
                            isClearable
                            required
                            
                                    />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                 Subject 2 <span className="red-alert">*</span>
                            </Typography>


                            <Sel
                            value={state.selectedOption2}
                            options={f_papers}
                            isLoading={!isLoaded}
                            onChange={onPaperChange.bind(this, 'paper_2')}
                            isClearable
                            required
                            
                                    />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                 Subject 3 <span className="red-alert">*</span>
                            </Typography>


                            <Sel
                            value={state.selectedOption3}
                            options={f_papers}
                            isLoading={!isLoaded}
                            onChange={onPaperChange.bind(this, 'paper_3')}
                            isClearable
                            required
                            
                                    />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                 Subject 4 <span className="red-alert">*</span>
                            </Typography>


                            <Sel
                            value={state.selectedOption4}
                            options={f_papers}
                            isLoading={!isLoaded}
                            onChange={onPaperChange.bind(this, 'paper_4')}
                            isClearable
                            required
                            
                                    />
                        </Grid>

                       {state.course_type=='HONOURS' && (state.semester=='3' || state.semester=='5') && <Grid item xs={12} sm={12} lg={3} md={3}>
                            <Typography
                                variant="subtitle2"

                            >
                                 Subject 5 <span className="red-alert">*</span>
                            </Typography>


                            <Sel
                            value={state.selectedOption5}
                            options={f_papers}
                            isLoading={!isLoaded}
                            onChange={onPaperChange.bind(this, 'paper_5')}
                            isClearable
                            required
                            
                                    />
                        </Grid>}


                        <Grid item xs={12} sm={12} lg={12} md={12} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={() => onSubmit()}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>}

            </Paper>}
        </div>
    )
}
