import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AuthService from './../auth_components/js/AuthService';
import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { APP_GOOD_NAME, DRAWER_WIDTH } from '../Helper/GlobalConstants';
import packageJson from './../package.alias.json'

const drawerWidth = DRAWER_WIDTH;

const styles = theme => ({
    root: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#2196F3'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        

    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'


    },
    navIconHide: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },

    drawerPaper: {
        width: drawerWidth,
        left: 0,
        minHeight: '95vh',
        
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth
        }
    },
    listSmall: {
        paddingLeft: '35px'
    },
    listSmall1: {
        paddingLeft: '25px',
        backgroundColor: '#fafafa'
    },
    smallText: {
        fontSize: '0.8em',

    },
    version: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '0.8em',
        backgroundColor: 'white'
    },
    listItemIcon: {
        minWidth: '40px'
    },
    listItemText: {
        fontWeight: 500,
        color: '#1F2B3D'
    }
});


const menuListObject = [
    {
        title: "Dasboard",
        link: "/",
        submenu: []
    },
    {
        title: "Master",
        link: "",
        submenu: [
            {
                title: "Course",
                link: "/master/course"
            },
            {
                title: "Department",
                link: "/master/department"
            },
            {
                title: "Subject",
                link: "/master/subject"
            },
            {
                title: "Paper",
                link: "/master/paper"
            },
            {
                title: "Session",
                link: "/master/session"
            },
            {
                title: "Section",
                link: "/master/section"
            },
           
            {
                title: "Leave",
                link: "/master/Leave"
            },
            {
                title: "Diary",
                link: "/master/diary"
            },
            {
                title: "Holiday",
                link: "/master/holiday"
            },
            {
                title: "Force Change Password",
                link: "/master/passwordchange"
            },
            {
                title: "Access Control",
                link: "/master/access"
            },
            {
                title: "Fees Structure",
                link: "/fees",
            },
            {
                title: "Doc Type",
                link: "/master/doctype"
            },
        ]
    },
    {
        title: "Employee",
        link: "/employee",
        submenu: []
    },
    // {
    //     title: "Alumni",
    //     link: "/alumni",
    //     submenu: []
    // },
    {
        title: "Student",
        link: "/student",
        submenu: []
    },
    {
        title: "Student Entry",
        link: "/studentbulkentry",
        submenu: []
    },
    {
        title: "Routine",
        link: "",
        submenu: [
            {
                title: "Routine",
                link: "/master/routine"
            },
            {
                title: "Routine View",
                link: "/master/routineview"
            },
            {
                title: "Routine Roomwise",
                link: "/master/routineroom"
            },

        ]
    },
    {
        title: "Notifications",
        link: "/notifications",
        submenu: []
    },
    {
        title: "Feedback",
        link: "",
        submenu: [
            {
                title: "Category",
                link: "/feedback/category"
            },
            {
                title: "Session",
                link: "/feedback/session"
            },
            {
                title: "Questions",
                link: "/feedback/questions"
            },
            {
                title: "Reports",
                link: "/feedback/reports"
            }
        ]
    },
    {
        title: "Leave",
        link: "",
        submenu: [
            {
                title: "Status",
                link: "/leave/status"
            },
            {
                title: "Reports",
                link: "/leave/reports"
            }
        ]
    },
    {
        title: "Attendance",
        link: "/attendancereport",
        submenu: []
    },
    {
        title: "Reports",
        link: "",
        submenu: [
            {
                title: "Student Report",
                link: "/studentreport"
            },
            {
                title: "Teacher Diary",
                link: "/teachersdiary"
            },
            {
                title: "Class Attendance",
                link: "/areport"
            },
            {
                title: "Student Attendance",
                link: "/sattreport"
            },
            {
                title: "All Attendance",
                link: "/sattreportzero"
            },

        ]
    },
    // {
    //     title: "Class Tests",
    //     link: "",
    //     submenu: [
    //         {
    //             title: "Subject Wise",
    //             link: "/classtest/subject"
    //         },
    //         {
    //             title: "Student Wise",
    //             link: "/classtest/student"
    //         },
    //         {
    //             title: "Submission Report",
    //             link: "/classtest/all"
    //         }
    //     ]
    // },
    // {
    //     title: "Exam papers",
    //     link: "/paper",
    //     submenu:[]
    // },
    // {
    //     title: "Student paper update",
    //     link: "/student-paper-update",
    //     submenu:[]
    // },
    {
        title: "Marks Entry",
        link: "",
        submenu: [
            {
                title: "Entry",
                link: "/marksmain"
            },
            {
                title: "View",
                link: "/viewform"
            }
        ]
    },

    // {
    //     title: "Accounts",
    //     link: "",
    //     submenu: [
    //         {
    //             title: "Financial Year",
    //             link: "/account/year"
    //         },
    //         {
    //             title: "Bank A/C",
    //             link: "/account/bank"
    //         },
    //         {
    //             title: "Heads",
    //             link: "/account/heads"
    //         }
    //     ]
    // },
    // {
    //     title: "Admission",
    //     link: "",
    //     submenu: [
    //         {
    //             title: "Fees Structure",
    //             link: "/fees",
    //             submenu: []
    //         },
    //         {
    //             title: "Subject Combination",
    //             link: "/scombination"
    //         }
    //     ]
    // },
    {
        title: "Logs",
        link: "/logs",
        submenu: []
    },

]


class DrawerMain extends React.Component {
    constructor(props) {
        super(props);

    }

    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        open: [false, false, false, false],
        version: packageJson.version
    };

    handleClick = (e) => {
        let open = this.state.open;
        open[e] = !this.state.open[e];
        this.setState({
            open
        })
    }



    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;

        let menu = [];

        menu = menuListObject.map((el, index) => {

            let i = [];
            let j = [];
            if (el.submenu.length > 0) {
                if (this.state.open[index]) {
                    i = <ExpandLess />
                    j =
                        <Collapse in={this.state.open[index]} timeout="auto" unmountOnExit>
                            <List dense>
                                {el.submenu.map((el, index) =>
                                    <Link to={el.link} key={index}>
                                        <ListItem button className={classes.listSmall1}>
                                            <ListItemText>
                                                <Typography className={classes.smallText} variant="body1">{el.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                } else {
                    i = <ExpandMore />
                    j = [];
                }
            }
            return <List disablePadding key={index} >
                {(el.link !== "") ? <Link to={el.link}>
                    <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemText >
                            <Typography variant="body2" className={classes.listItemText}>{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>
                </Link> : <ListItem button onClick={this.handleClick.bind(this, index)}>
                        <ListItemText >
                            <Typography variant="body2" className={classes.listItemText}>{el.title}</Typography>
                        </ListItemText>
                        {(el.submenu.length > 0) ? i : null}
                    </ListItem>}
                {j}
            </List>
        }


        )



        return (
            <div >

                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    anchor="left"
                >

                    <div className={classes.toolbar}>
                        <Typography>
                            {APP_GOOD_NAME}
                        </Typography>
                        <Typography
                            variant="h6"
                        >
                            Super Admin Login
                        </Typography>

                    </div>
                    <Divider />
                    {menu}
                    <div className={classes.version}>
                        {`v ${this.state.version}`}
                    </div>
                </Drawer>
            </div>
        );
    }
}

DrawerMain.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerMain);
