export const APP_NAME = "BH_COLLEGE";
export const APP_GOOD_NAME = "BH College";
export const DEFAULT_COLOR = "#2196F3";
export const DRAWER_WIDTH = 180;

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backend.bhcollege.ac.in/public' : 'http://localhost/bhcollege/api/public';


export const ACCESS_CONTROL = [
    {
        text: 'ACCOUNTS',
        value: 'ACC'
    },
    {
        text: 'ADMISSION',
        value: 'ADM'
    },
    {
        text: 'FEES COLLECTION',
        value: 'FEE'
    },
    {
        text: 'REPORTS',
        value: 'REP'
    },
    {
        text: 'LEAVE-PRINCIPAL',
        value: 'LEAVEPRINCE'
    },
    {
        text: 'HOSTEL',
        value: 'HOSTEL'
    },
    {
        text: 'API REPORT',
        value: 'API_REPORT'
    },
    {
        text: 'ASSETS MANAGEMENT',
        value: 'INV'
    },
    {
        text: 'E-Governance',
        value: 'EGOV'
    }
]

export const COURSE_TYPE_LIST = ["HONOURS","REGULAR"];
export const GENDER_LIST = ["MALE","FEMALE","OTHERS"];
export const CASTE_LIST = ["GENERAL","ST(P)","ST(H)","SC", "OBC", "MOBC"];
export const RELIGION_LIST = ["HINDUISM", "ISLAM", "CHRISTIANISM", "JAINISM", "SIKKHISM","CHRISTIANITY","BUDDHISM"];


export const LETTER_GRADES = [
    {
        text: 'O (Outstanding)',
        key: 'O',
        gp: 10
    },
    {
        text: 'A+ (Excellent)',
        key: 'A+',
        gp: 9
    },
    {
        text: 'A (Very Good)',
        key: 'A',
        gp: 8
    },
    {
        text: 'B+ (Good)',
        key: 'B+',
        gp: 7
    },
    {
        text: 'B (Above Average)',
        key: 'B',
        gp: 6
    },
    {
        text: 'C (Average)',
        key: 'C',
        gp: 5
    },
    {
        text: 'P (Pass)',
        key: 'P',
        gp: 4
    },
    {
        text: 'F (Fail)',
        key: 'F',
        gp: 0
    },
    {
        text: 'Ab (Absent)',
        key: 'Ab',
        gp: 0
    }

]