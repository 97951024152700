import React from 'react'
import { Grid, Card, CardHeader } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { red, purple, orange, blue, green, lightGreen } from '@material-ui/core/colors'

export default function NumberCount() {

    const dashboardCount = useSelector(state => state.dashboardCount)
    const colors = [red[600], purple[600], lightGreen[600], orange[600], blue[600], green[600]]
    return (
        <div>

            <Grid container spacing={2}>
                {dashboardCount.map((el, index) =>
                    <Grid key={index} item xs={6} sm={6} md={2} lg={2}>
                        <GoodCard
                            value={el.value}
                            text={el.text}
                            link={el.link}
                            color={colors[index]}
                        />
                    </Grid>
                )}

            </Grid>
        </div>
    )
}

export function GoodCard({ value, text, link, color }) {
    const history = useHistory();
    return (
        <React.Fragment>
            <Card raised
                onClick={() => history.push(link)}
                style={{
                    cursor: 'pointer',
                    backgroundColor: color,
                    color: 'white'
                }}>
                <CardHeader
                    title={value}
                    subheader={text}
                    subheaderTypographyProps={{color: 'white'}}
                />
            </Card>
        </React.Fragment>
    )
}
