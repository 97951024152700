import { ADD_SESSIONS, ADD_COURSES, ADD_EMPLOYEE, MSG_STATUS, MSG_MESSAGE, MSG_CLOSE, ADD_SUBJECTS, ADD_DEPARTMENT, DASHBOARD_COUNT } from "../constants/action-types";
const initialState = {
    courses: [],
    sessions: [],
    employee: [],
    departments: [],
    dashboardCount: [],
    subjects: [],
    msg_status: false,
    msg_message: '',
};
function rootReducer(state = initialState, action) {


    if (action.type === ADD_COURSES) {
        return Object.assign({}, state, {
            courses: action.payload
        });
    }


    if (action.type === ADD_SESSIONS) {
        return Object.assign({}, state, {
            sessions: action.payload
        });
    }

    if (action.type === ADD_EMPLOYEE) {
        return Object.assign({}, state, {
            employee: action.payload
        });
    }

    if (action.type === ADD_DEPARTMENT) {
        return Object.assign({}, state, {
            departments: action.payload
        });
    }

    if (action.type === ADD_SUBJECTS) {
        return Object.assign({}, state, {
            subjects: action.payload
        });
    }

    if (action.type === MSG_STATUS) {
        return Object.assign({}, state, {
            msg_status: action.payload
        });
    }

    if (action.type === MSG_MESSAGE) {
        return Object.assign({}, state, {
            msg_message: action.payload
        });
    }

    if (action.type === MSG_CLOSE) {
        return Object.assign({}, state, {
            msg_status: false,
            msg_message: ''
        });
    }

    if (action.type === DASHBOARD_COUNT) {
        return Object.assign({}, state, {
            dashboardCount: action.payload
        });
    }

    return state;
}
export default rootReducer;