import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withStyles } from "@material-ui/core/styles";


import { Provider } from "react-redux";
import store from "./redux/store/index";
import { getCourses, getSessions, getEmployeeSmallList, getAllSubjects, getDepartments, getDahboardCount } from './redux/actions';
import { BrowserRouter } from 'react-router-dom';

const styles = theme => ({
  "@global": {
    html: {
      fontSize: 13,
      [theme.breakpoints.up("sm")]: {
        fontSize: 14
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 12
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 13
      }
    }
  },
  typography: {
    fontSize: 12,
  }
});


// initialise value
store.dispatch(getCourses());
store.dispatch(getSessions());
store.dispatch(getEmployeeSmallList());
store.dispatch(getAllSubjects());
store.dispatch(getDepartments());
store.dispatch(getDahboardCount());

const AppStyled = withStyles(styles)(App);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <AppStyled />
    </BrowserRouter>
  </Provider>, document.getElementById('root'));


unregister();
