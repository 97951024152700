export const ADD_COURSES = "ADD_COURSES";
export const ADD_SESSIONS = "ADD_SESSIONS";
export const ADD_SEMESTERS = "ADD_SEMESTERS";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_SUBJECTS = "ADD_SUBJECTS";
export const MSG_STATUS = "MSG_STATUS";
export const MSG_MESSAGE = "MSG_MESSAGE";
export const MSG_CLOSE = "MSG_CLOSE";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const DASHBOARD_COUNT = "DASHBOARD_COUNT";
