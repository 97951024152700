import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';

import LoadingMain from './LoadingMain';
import PaperContainer from './Containers/PaperContainer';
import PaperUpdateContainer from './Containers/PaperUpdateContainer';




const InitialisationContainer = React.lazy(() => import('./Containers/InitialisationContainer'));
const NoticeContainer = React.lazy(() => import('./Containers/NoticeContainer'));
const MasterContainer = React.lazy(() => import('./Containers/MasterContainer'));
const EmployeeContainer = React.lazy(() => import('./Containers/EmployeeContainer'));
const FeedbackContainer = React.lazy(() => import('./Containers/FeedbackContainer'));
const SettingsContainer = React.lazy(() => import('./Containers/SettingsContainer'));
const LeaveContainer = React.lazy(() => import('./Containers/LeaveContainer'));
const StudentContainer = React.lazy(() => import('./Containers/StudentContainer'));
const ExcelUploadContainer = React.lazy(() => import('./Containers/ExcelUploadContainer'));
const LogContainer = React.lazy(() => import('./Containers/LogContainer'));
const NotificationsContainer = React.lazy(() => import('./Containers/NotificationsContainer'));
const AttendanceReportContainer = React.lazy(() => import('./Containers/AttendanceReportContainer'));
const FeesContainer = React.lazy(() => import('./Containers/FeesContainer'));
const AlumniContainer = React.lazy(() => import('./Containers/AlumniContainer'));
const StudentBulkContainer = React.lazy(() => import('./Containers/StudentBulkContainer'));
const ClasstestReportContainer = React.lazy(() => import('./Containers/ClasstestReportContainer'));
const MarksContainer = React.lazy(() => import('./Containers/MarksContainer'));




class App extends Component {
  render() {
    return (
      <Suspense fallback={<LoadingMain />}>
 

          <div>
            <Switch>
              <Route path="/login" component={Login} />
              <Route exact path="/" component={HomePage} />
              <Route exact path="/notice" component={NoticeContainer} />
              <Route exact path="/initialisation" component={InitialisationContainer} />
              <Route exact path="/settings" component={SettingsContainer} />

              <Route exact path="/master/course" component={MasterContainer} />
              <Route exact path="/master/department" component={MasterContainer} />
              <Route exact path="/master/subject" component={MasterContainer} />
              <Route exact path="/master/holiday" component={MasterContainer} />
              <Route exact path="/master/stream" component={MasterContainer} />
              <Route exact path="/master/session" component={MasterContainer} />
              <Route exact path="/master/leave" component={MasterContainer} />
              <Route exact path="/master/class" component={MasterContainer} />
              <Route exact path="/master/routine" component={MasterContainer} />
              <Route exact path="/master/routineview" component={MasterContainer} />
              <Route exact path="/master/routineroom" component={MasterContainer} />
              <Route exact path="/master/section" component={MasterContainer} />
              <Route exact path="/master/access" component={MasterContainer} />
              <Route exact path="/master/passwordchange" component={MasterContainer} />
              <Route exact path="/master/section" component={MasterContainer} />
              <Route exact path="/master/diary" component={MasterContainer} />
              <Route exact path="/master/paper" component={MasterContainer} />
              <Route exact path="/master/doctype" component={MasterContainer} />


              <Route exact path="/employee" component={EmployeeContainer} />
              <Route exact path="/student" component={StudentContainer} />
              <Route exact path="/studentbulkentry" component={StudentBulkContainer} />


              <Route exact path="/alumni" component={AlumniContainer} />

              <Route exact path="/feedback/category" component={FeedbackContainer} />
              <Route exact path="/feedback/session" component={FeedbackContainer} />
              <Route exact path="/feedback/questions" component={FeedbackContainer} />
              <Route exact path="/feedback/reports" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/students" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/teachers" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/employers" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/analysis" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/analysis-student-new" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/analysis-employers" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/analysis-teacher" component={FeedbackContainer} />
              <Route exact path="/feedback/reports/guardians" component={FeedbackContainer} />
             
              <Route exact path="/feedback/reports/analysis/new" component={FeedbackContainer} />

              <Route exact path="/leave/status" component={LeaveContainer} />
              <Route exact path="/leave/reports" component={LeaveContainer} />


              <Route exact path="/excel" component={ExcelUploadContainer} />
              <Route exact path="/logs" component={LogContainer} />
              <Route exact path="/fees" component={FeesContainer} />


              <Route exact path="/notifications" component={NotificationsContainer} />
              <Route exact path="/attendancereport" component={AttendanceReportContainer} />
              <Route exact path="/areport" component={AttendanceReportContainer} />
              <Route exact path="/editattendance/:id" component={AttendanceReportContainer} />

              <Route exact path="/classtest/subject" component={ClasstestReportContainer} />
              <Route exact path="/classtest/student" component={ClasstestReportContainer} />
              <Route exact path="/classtest/all" component={ClasstestReportContainer} />

              <Route exact path="/teachersdiary" component={AttendanceReportContainer} />
              <Route exact path="/studentreport" component={AttendanceReportContainer} />
              <Route exact path="/sattreport" component={AttendanceReportContainer} />
              <Route exact path="/sattreportzero" component={AttendanceReportContainer} />


              <Route exact path="/entryform" component={MarksContainer} />
                <Route exact path="/marksmain" component={MarksContainer} />
                <Route exact path="/cbcs" component={MarksContainer} />
                <Route exact path="/viewform" component={MarksContainer} />
                <Route exact path="/printform" component={MarksContainer} />
                <Route exact path="/editform" component={MarksContainer} />
                <Route
                  exact
                  path="/editformcbcs"
                  component={MarksContainer}
                />
              <Route exact path="/paper" component={PaperContainer} />
              <Route exact path="/student-paper-update" component={PaperUpdateContainer} />


            </Switch>
          </div>

      
      </Suspense>
    );
  }
}

export default App;
