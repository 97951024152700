import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Add from '@material-ui/icons/Add';
import Done from '@material-ui/icons/Done'
import { lightGreen } from '@material-ui/core/colors';



export default class PaperTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el) {
        this.props.actionClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value) {
        if (Array.isArray(value) && value.length > 0) {
            let new_arr = value.map((el, index) => el.label);

            return new_arr.join(",");
        } else {
            return '';
        }
    }

    checkStatus = (value) => {
        if (value === 0) {
            return 'NO';
        } else if (value === 1) {
            return 'YES';
        }
    }

    checkAdmission = (value) => {
        if (value === 1) {
            return <Done style={{ color: lightGreen[600] }} />
        } else {
            return null;
        }
    }


    render() {
        const columns = [
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Stream",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Semester",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Type",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Code",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Paper",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Credit (Theory)",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Credit (Practical)",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Paper Credit (Project)",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: 'none',
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton><Add onClick={this.props.onAddClick} /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if (table_data.length > 0) {
            data = table_data.map((el, index) =>
            [       el.course,
                    el.stream,
                    el.semester,
                    el.course_type,
                    `${el.dept_name} - ${el.dept_code}`,
                    el.paper_type,
                    el.paper_code,
                    el.paper_name,
                    
                    el.paper_tp_type,
                   el.paper_credit_theory,
                   el.paper_credit_practical,
                   el.paper_credit_project,
                    <Launch onClick={this.onActionClick.bind(this, el)} />
                ]
            )
        } else {
            data = [];
        }

        return (
            <div>
                <MUIDataTable
                    title={"Subject Papers"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
