import React, { useEffect, useState } from 'react'
import { Grid, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core'
import { blue, green, red, orange } from '@material-ui/core/colors'
import { PostData, GetData } from '../../api/service'
import {Chart} from 'react-google-charts'
export default function ClassAttendance() {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <AttendanceCard />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <GraphCard />
                </Grid>
            </Grid>
        </div>
    )
}

export function AttendanceCard() {

    const [data, setData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        let sd = {
            date: '',
            teacher: ''
        }

        PostData(`/getattendancereports`, sd)
            .then((resp) => {
                //console.log(resp);
                setData(resp);
                setIsLoaded(true)
            })
    }, [])



    return (
        <React.Fragment>
            <Card raised>
                <CardHeader
                    title={`Today's Classes`}
                    subheader={`Statistics`}
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                            <Typography variant="caption">
                                Total Allotted
                            </Typography>
                            <Typography variant="h4" style={{ color: blue[700] }}>
                                {isLoaded ? data.alloted : '...'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                            <Typography variant="caption">
                                Total Attened
                            </Typography>
                            <Typography variant="h4" style={{ color: green[700] }}>
                                {isLoaded ? data.held : '...'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                            <Typography variant="caption">
                                Total Cancelled
                            </Typography>
                            <Typography variant="h4" style={{ color: orange[700] }}>
                                {isLoaded ? data.canceled : '...'}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: 'center' }}>
                            <Typography variant="caption">
                                Total Not Attended
                            </Typography>
                            <Typography variant="h4" style={{ color: red[700] }}>
                                {isLoaded ? data.not_attended : '...'}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}


export function GraphCard() {
    const [data, setData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        GetData(`/mothlyattendancereport`)
            .then((resp) => {
                //console.log(resp);
                let r = [];
                if(Array.isArray(resp[0]) && resp[0].length > 0){
                    resp[0].map((el,index) => {
                        r.push([new Date(resp[1],resp[2],el[0]), el[1]])
                    })
                }
                setData(r);
                
                setIsLoaded(true)
            })
    }, [])
    return (
        <React.Fragment>
            <Card raised>
                <CardHeader
                    title={`Monthly Reports`}
                    subheader={`Total Alloted vs Total Attended (This Month)`}
                />
                <Divider />
                <CardContent>
                    {isLoaded ? <Chart
                        width={'`00%`'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            [{type: 'date', label: 'Day'},
                            'No of attended classes'],
                            ...data
                        ]
                        
                    }
                        options={{
                            hAxis: {
                                title: 'Date',
                            },
                            vAxis: {
                                title: 'Attended Class',
                                format: '0'
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />: 'Loading . .'}
                </CardContent>
            </Card>
        </React.Fragment>
    )
}