import React, { Component } from 'react';
import './css/welcome.css';
import { GetData, PostData } from '../../api/service';
import TopLabel from '../DashBoard/TopLabel';
import NumberCount from '../DashBoard/NumberCount';
import ClassAttendance from '../DashBoard/ClassAttendance';


export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  }
  
  render() {
    return (
      <div>
        <TopLabel />

        <br />

        <NumberCount />

        <br />

        <ClassAttendance />
      </div>
      
    )
  }
}
