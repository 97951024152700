import AuthService from './../auth_components/js/AuthService';
import { BaseURL } from '../Helper/GlobalConstants';
export const addOnURL = process.env.NODE_ENV === 'production' ? 'https://backend.bhcollege.ac.in/public' : 'http://localhost/bhcollege/api/public';



export function PostData(type, userData) {
    let Auth = new AuthService();
    
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'admin'
            }
        })
            .then((resp) => 
               {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                }else{
                    return resp.json();
                }
                
               }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


export function GetData(type) {
    let Auth = new AuthService();
    
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'admin'
            }
        })
            .then((resp) => {
                {
                    if (!resp.ok) {
                        if (resp.status >= 400 && resp.status < 500) {
                            /* return resp.json().then(data => {
                                let err = { errorMessage: data };
                                alert(err.errorMessage);
                                //logout
                                //Auth.logout();
                                throw err;
                            }) */

                            throw resp.text();
                        }
                        else {
                            /* let err = { errorMessage: "Please try again later" };
                            alert(`Something went Wrong!! Status: ${resp.status}`);
                            //logout
                            //Auth.logout();
                            throw err; */
                            throw resp.text();
                        }
                    } else {
                        return resp.json();
                    }

                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((err) => {
                    console.log(err)
                })
                reject(error);
            });
    });
}


export function PutData(type, userData) {
    let Auth = new AuthService();
    
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'PUT',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'admin'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((resp) => {
                    console.log(error);
                })
                reject(error);
            });
    });
}


export function DeleteData(type) {
    let Auth = new AuthService();
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'admin'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((resp) => {
                    console.log(error);
                })
                reject(error);
            });
    });
}



export function PostFormData(type, userData) {
    let Auth = new AuthService();

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: userData,
            headers: {
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'admin'
            }
        })
            .then((resp) => {
                //console.log(resp);
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        })
                    }
                    else {
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err;
                    }
                }
                return resp.json();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function GetBlob(type) {
    let Auth = new AuthService();
    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Auth.getToken()
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    
                        let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        throw err;
                    
                }
                return resp.blob();
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function DeleteAddOnData(type) {
    let Auth = new AuthService();
    
    return new Promise((resolve, reject) => {
        fetch(addOnURL + type, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'STUDENT'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((resp) => {
                    console.log(error);
                })
                reject(error);
            });
    });
}

export function GetAddOnData(type) {
    let Auth = new AuthService();

    return new Promise((resolve, reject) => {
        fetch(addOnURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
                'Unit': 'STUDENT'
            }
        })
            .then((resp) => {
                {
                    if (!resp.ok) {
                        if (resp.status >= 400 && resp.status < 500) {
                            /* return resp.json().then(data => {
                                let err = { errorMessage: data };
                                alert(err.errorMessage);
                                //logout
                                //Auth.logout();
                                throw err;
                            }) */
                          //Auth.logout();

                            throw resp.text();
                        }
                        else {
                            /* let err = { errorMessage: "Please try again later" };
                            alert(`Something went Wrong!! Status: ${resp.status}`);
                            //logout
                            //Auth.logout();
                            throw err; */
                           //Auth.logout();
                            throw resp.text();
                        }
                    } else {
                        return resp.json();
                    }

                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                error.then((err) => {
                    console.log(err)
                })
                reject(error);
            });
    });
}
