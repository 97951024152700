import { ADD_COURSES, ADD_SESSIONS, ADD_EMPLOYEE, MSG_STATUS, MSG_MESSAGE, MSG_CLOSE, ADD_SUBJECTS, ADD_DEPARTMENT, DASHBOARD_COUNT } from "../constants/action-types";
import { GetData } from "../../api/service";
import AuthService from "../../auth_components/js/AuthService";

const Auth = new AuthService;


export function getCourses() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getcourse`)
                .then((resp) => {
                    dispatch({ type: ADD_COURSES, payload: resp })
                })
        }
    };
}


export function getDepartments() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getdepartment`)
                .then((resp) => {
                    dispatch({ type: ADD_DEPARTMENT, payload: resp })
                })
        }
    };
}

export function getSessions() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getsession`)
                .then((resp) => {
                    dispatch({ type: ADD_SESSIONS, payload: resp })
                })
        }
    };
}


export function getEmployeeSmallList() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getemployeesmalldetails`)
                .then((resp) => {
                    dispatch({ type: ADD_EMPLOYEE, payload: resp })
                })
        }
    };
}

export function getAllSubjects() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/getsubject`)
                .then((resp) => {
                    dispatch({ type: ADD_SUBJECTS, payload: resp })
                })
        }
    };
}


export function getDahboardCount() {
    return function (dispatch) {
        if (Auth.loggedIn()) {
            return GetData(`/${Auth.getApikey()}/getcountdashboard`)
                .then((resp) => {
                    dispatch({ type: DASHBOARD_COUNT, payload: resp })
                })
        }
    };
}


export function showMessage({ status, message }) {
    return function (dispatch) {
        dispatch({ type: MSG_STATUS, payload: status });
        dispatch({ type: MSG_MESSAGE, payload: message });

        setTimeout(() => dispatch({ type: MSG_CLOSE, payload: '' }), 2000);
    };
}
