import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import Paper from '../Components/Paper/Paper';
import AuthService from '../auth_components/js/AuthService';
import { Card, CardContent } from '@material-ui/core';
import SubjectPaperUpdate from '../Components/SubjectPaperUpdate/SubjectPaperUpdate';

class PaperUpdateContainer extends Component {
    constructor(props) {
        super(props);
        let Auth = new AuthService;

        let user = Auth.getUser();

        this.state = {
            apikey: user.apikey,
            username: user.email,
            role: user.role,
            user: user
        }
    }
    render() {
        return (
            <div>
                <Navbar history={this.props.history} />
                <br /> <br /> <br />
                
                    <div className="main-body-no-padding" style={{paddingLeft:'12%'}}>
                    <SubjectPaperUpdate
                        history={this.props.history}
                        username={this.state.username}
                        name={this.state.name}
                        apikey={this.state.apikey} />
                </div>
                   
                
            </div>
        )
    }
}

export default withAuth(PaperUpdateContainer);