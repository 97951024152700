import React, { Component } from 'react'
import { withStyles, Drawer, Typography, Divider, TextField, Button, AppBar, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core';
import Sel from 'react-select';
import zIndex from '@material-ui/core/styles/zIndex';

const drawerWidth = 500;

const styles = theme => ({
    root: {
        width: drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%'
    },
    textStyle: {
        fontWeight: 400
    },
    paddingForm: {
        padding: '20px',
        
    },
    formControl: {
        width: '100%',
        marginTop: '10px'
    }
})
class PaperAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
           
            isSubLoaded: false,
            course:'TDC',
            stream:'ARTS',
            semester:1,
            course_type:'HONOURS',
            dept_code: '',
            paper_type:'HC',
            paper_code: '',
            paper_name: '',
            paper_tp_type: 'Theory',
            paper_credit_theory: 0,
            paper_credit_practical: 0,
            paper_credit_project: 0,
            id: '',
            selectedOption: null,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    handleSChange = (name, dept_code) => {
        this.setState({
            [name]: dept_code
        })
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.edit) {
           let tt = this.props.departments.filter((el)=>el.dept_code==this.props.edit_data.dept_code);
            this.setState({
                ...this.props.edit_data,selectedOption:tt
            })
        } else {
            this.setState({
                dept_code: '',
                paper_code: '',
               
                id: '',
                selectedOption: null,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.edit) {
                
                let tt = this.props.departments.filter((el)=>el.dept_code==this.props.edit_data.dept_code);
            this.setState({
                ...this.props.edit_data,
                selectedOption:tt,
               
            })
            } else {
                this.setState({
                    course:'TDC',
                    stream:'ARTS',
                    semester:1,
                    course_type:'HONOURS',
                    dept_code: '',
                    paper_type:'HC',
                    paper_code: '',
                    paper_name: '',
                    paper_tp_type: 'Theory',
                    paper_credit_theory: 0,
                    paper_credit_practical: 0,
                    paper_credit_project: 0,
                    id: '',
                    selectedOption: null,
                })
            }
        }
    }


    onSubmit(e) {
        e.preventDefault();

        const data = this.state;

        if(data.course !='' && data.stream !='' && data.semester !='' && data.course_type !='' && data.dept_code !='' && data.paper_type !='' && data.paper_code !='' && data.paper_name !='' && data.paper_tp_type !=''){

        if (this.props.edit) {
            this.props.esetData(data);

        } else {
            this.props.setData(data);
            this.setState({
               
                
            
            paper_code: '',
            paper_name: '',
            
            paper_credit_theory: 0,
            paper_credit_practical: 0,
            paper_credit_project: 0,
               
                id: ''
            })
        }

    }

    }

    onDeleteClick(id) {
        this.props.onDeleteClick(id);
    }

    onSubjectChange = (type, value) => {
        
       
        if (type == 'subject_obj') {
            if(value !=null)
            this.setState({
                selectedOption: value,
                dept_code: value.dept_code
            })

            else this.setState({
                selectedOption: null,
                dept_code: ''
            })
        }

    }

    render() {
        let { classes } = this.props;
        


        return (
            <div>
                <Drawer
                    className={classes.root}
                    anchor="right"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >

                    <AppBar position="static" color={this.props.edit ? "secondary" : "primary"} >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" >
                                {this.props.edit ? "Edit DATA" : "Add New"}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.paddingForm}>

                        <form onSubmit={this.onSubmit}>

                            <Grid container spacing={2}>

                            <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Course</InputLabel>
                                        <Select
                                            value={this.state.course}
                                            onChange={this.onChange}
                                            name="course"
                                            inputProps={{
                                                name: 'course'
                                            }}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="TDC">TDC</MenuItem>
                                            <MenuItem value="PG">PG</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Stream</InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.onChange}
                                            name="stream"
                                            inputProps={{
                                                name: 'stream'
                                            }}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="ARTS">ARTS</MenuItem>
                                            <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Semester</InputLabel>
                                        <Select
                                            value={this.state.semester}
                                            onChange={this.onChange}
                                            name="semester"
                                            inputProps={{
                                                name: 'semester'
                                            }}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {[1,2,3,4,5,6].map((el,index)=>
                                            <MenuItem key={index} value={el}>{el}</MenuItem>
                                            )}
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Course Type</InputLabel>
                                        <Select
                                            value={this.state.course_type}
                                            onChange={this.onChange}
                                            name="course_type"
                                            inputProps={{
                                                name: 'course_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="HONOURS">HONOURS</MenuItem>
                                            <MenuItem value="REGULAR">REGULAR</MenuItem>
                                            
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Select Subejct</InputLabel>
                                        <Sel
                                        value={this.state.selectedOption}
                                        options={this.props.departments}
                                        isLoading={!this.props.isSubLoaded}
                                        onChange={this.onSubjectChange.bind(this, 'subject_obj')}
                                        isClearable
                                        required
                                        menuPortalTarget={document.body} 
                                        styles={{
                                            // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 9999 }),
                                            menuPortal: provided => ({ ...provided, zIndex: 9999 })
                                          }}
                                    />
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Paper Type</InputLabel>
                                        <Select
                                            value={this.state.paper_type}
                                            onChange={this.onChange}
                                            name="paper_type"
                                            inputProps={{
                                                name: 'paper_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="CORE">CORE</MenuItem>
                                            <MenuItem value="AECC-I">AECC-I</MenuItem>
                                          
                                            <MenuItem value="GE">Generic Elective</MenuItem>
                                            
                                            <MenuItem value="AECC-II">AECC-II</MenuItem>

                                            <MenuItem value="DSE">Discipline Specific Elective</MenuItem>
                                            <MenuItem value="SEC">Skill Enhencement Course</MenuItem>
                                           
                                            
                                            
                                        </Select>
                                    </FormControl>
                                </Grid>

                               
                               
                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Paper Code"
                                        className={classes.textField}
                                        type="text"
                                        name="paper_code"
                                        autoComplete="Paper-code"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_code}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <TextField
                                        label="Paper Name"
                                        name="paper_name"
                                        className={classes.textField}
                                        type="text"
                                        autoComplete="Paper-name"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                

                                <Grid item xs={12} lg={6} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel shrink className={classes.textStyle}>Theory/Practical/Project</InputLabel>
                                        <Select
                                            value={this.state.paper_tp_type}
                                            onChange={this.onChange}
                                            name="paper_tp_type"
                                            inputProps={{
                                                name: 'paper_tp_type'
                                            }}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            
                                            <MenuItem value="TH">Theory</MenuItem>
                                           
                                            <MenuItem value="TH_PR">Theory & Practical</MenuItem>
                                            <MenuItem value="PRJ">Project</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>

                                

                               <Grid item xs={12} lg={6} md={6}>

                                    <TextField
                                        label="Paper Credit (Theory)"
                                        className={classes.textField}
                                        type="number"
                                        name="paper_credit_theory"
                                        autoComplete="paper-credit-theory"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_credit_theory}
                                        onChange={this.onChange}
                                        inputProps={{min:0}}
                                        
                                    />

                                </Grid>

                             <Grid item xs={12} lg={6} md={6}>

                                    <TextField
                                        label="Paper Credit (Practical)"
                                        className={classes.textField}
                                        type="number"
                                        name="paper_credit_practical"
                                        autoComplete="paper_credit_practical"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_credit_practical}
                                        onChange={this.onChange}
                                        inputProps={{min:0}}
                                    />

                                </Grid>

                              <Grid item xs={12} lg={6} md={6}>

                                    <TextField
                                        label="Paper Credit (Project)"
                                        className={classes.textField}
                                        type="number"
                                        name="paper_credit_project"
                                        autoComplete="paper-credit-project"
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.textStyle
                                            }
                                        }}
                                        value={this.state.paper_credit_project}
                                        onChange={this.onChange}
                                        inputProps={{min:0}}
                                    />

                                </Grid>

                            </Grid>

                            <br />

                            <div>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color={this.props.edit ? "secondary" : "primary"}
                                    className={classes.button}>
                                    {this.props.edit ? "Update" : "Submit"}
                                </Button>

                                {this.props.edit ?
                                    <span>&nbsp;
                                    <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.onDeleteClick.bind(this, this.props.edit_data.id)}
                                        >
                                            Delete
                                    </Button>
                                    </span>

                                    : null}
                            </div>
                        </form>

                    </div>



                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(PaperAdd)